@use 'sass:math';

@include selection {
	background-color: tint($base-color, 15%);
	color: $white100;
}

body {
	$body-background: $white150;
	
	background: $body-background;
	font-size: rem(15);

	@include media($mob-min-width){
		font-size: rem(15);
	}

	@include media($tab-min-width){
		font-size: rem(16);
	}
}

a {
	color: tint($accent-color, 15%);

	@include selection {
		background-color: shade($accent-color, 5%);
		color: $white100;
	}

	&:hover,
	&:active {
		cursor: pointer;
		color: shade($accent-color, 15%);
	}

	&:visited {
		color: shade($accent-color, 10%);
	}
}

p,
li,
span {
	font-family: $body-family;
	line-height: 1.8;
	// color: $black400;
	color: $base-color300;
}

h1,
h2 {
	font-family: $header-family;
	color: $base-color200;
}

article {
	@include sect-padding;
	@include clip-path-both;
	background: $white100;
}


hr,
span {
	&.short {
		margin: rem(30) 0;
		width: rem(100);
		border-width: rem(3) 0 0 0;
		border-style: solid;
	}

	&.red {
		border-color: $accent-color;
	}

	&.center {
		margin: 0 auto;
		margin-top: rem(30);
		margin-bottom: rem(30);
	}

	&.header {
		margin-top: rem(math.div(30,2));
	}
}

