.contact-me {
	$contact-background: $white150;
	$contact-background: $background-color200;
	$contact-font-family: $suppl-family;
	$contact-font-color: $base-color200;

	background-color: $contact-background;
	.links-out {
		@include inline-lists;

		li a {
			margin: rem(5);
			padding: 0 rem(10);
			display: block;
			line-height: 1;

			&:first-child {
				margin-left: 0;
				padding-left: 0;
			}

			span {
				font-family: $contact-font-family;
				font-size: rem(24);
				font-style: italic;
				color: $contact-font-color;
				font-weight: 300;
			}
		}

	}
}
