.resume {
	background-color: $white100;

	.work-experience,
	.education,
	.skills-competency {
		margin: rem(30) 0;
	}

	.grid {
		display: grid;
		max-width: $content-width;
		margin: 0 auto;
		grid-template-columns: 1fr;
	}

	.pretty-good,
	.kinda-okay {
		@include span-columns(12);

		@include media($tab-min-width){
			@include span-columns(6);
		}
	}
	
	.examples li {
		color: tint($base-color300, 30%);
		padding: rem(5) 0;
		font-size: rem(14);
	} 

	.education,
	.kinda-okay {
		@include media($tab-min-width){
			@include omega();
		}
	}

	.item {
		@include inline-block-container;
		margin-bottom: rem(50);

		> * {
			display: block;
			position: relative;
		}

		.title {
			font-family: $header-family;
			font-size: rem(16);
			font-weight: 400;
			color: $base-color300;
			line-height: 1.15;
			margin-bottom: rem(10);
			font-size: rem(20);
			@include media($mob-min-width){
				font-size: rem(21);
			}
			@include media($tab-min-width){
				font-size: rem(22);
			}
		}

		.role,
		.period {
			font-family: $suppl-family;
			margin: 0;
			margin-bottom: rem(5);
		}

		.role {
			// color: tint($black400, 10%);
			color: saturate($accent-color, 1.1);
			text-transform: uppercase;
			font-size: rem(15);
			letter-spacing: 0.5px;
			line-height: 1.35;
		}

		.period {
			color: shade($base-color, 25%);
			font-size: rem(14);
			margin-bottom: rem(10);
			font-style: italic;
		}

		.desc {
			padding-left: rem(20);

			* {
				line-height: 1.7;
			}

			li,
			span {
				margin-bottom: rem(5);
			}
		}
	}
}

.pdf {
	background: $background-color200;
}
