.row {
	@include row();
	max-width: $content-width;
	margin: 0 auto;
}

.right { float: right;}

.clearfix { clear: both;}

.text-center {
	text-align: center;
}

.hidden {
	display: none !important;
}

.inline-lists {
	@include inline-lists;
}

.read-more {
	color: $base-color200;

	&:hover {
		cursor: pointer;
	}
}
