// used in post list as well. blegh
.post-meta {
	color: tint($base-color300, 20%);
}

.post {
	// @include outer-container();

	.post-content-wrapper {
		@include row();
	}

	.post-cover-image,
	.post-content {
		@include row();
		margin-bottom: rem(15);
		// @include span-columns(12);
		// @include media($tab-min-width){
			// @include span-columns(8);
		// }
		// @include media($lap-min-width){
			// @include span-columns(9);
		// }
		// @include media($desk-min-width){
		// 	@include span-columns(10);
		// }
	}

	.post-meta {
		// @include span-columns(12);
		@include row();
		margin-bottom: rem(30);
		// @include omega();

		.post-meta__date-author {
			@include span-columns(6);
		}

		.post-meta__attrib {
			@include span-columns(6);
			@include omega();
			text-align: right;
		}

		.post-meta__site-link {
			display: block;
			font-size: rem(16);
			font-weight: 600;
			text-transform: uppercase;
			color: $base-color300;
		}

		// @include media($tab-min-width){
		// 	@include span-columns(4);
		// }
		// @include media($lap-min-width){
		// 	@include span-columns(3);
		// }
		// @include media($desk-min-width){
		// 	@include span-columns(2);
		// }
	}

	.post-inner-images {
		img {
			display: block;
			width: 100%;
			position: relative;
			margin-bottom: rem(15);
		}
	}

	.post-skills {
		// $post-skill-color: $black100;
		$post-skill-color: $base-color;

		.skill-list {
			@extend .inline-lists;

			.item {
				@include block-tags;
				@include transition(all 0.25s ease-in-out);
				background-color: transparent;
				font-family: $suppl-family;
				font-size: rem(14);
				line-height: normal;
				color: $post-skill-color;

				&.checked,
				&:hover,
				&:active {
					cursor: pointer;
					border-color: shade($accent-color, 10%);
					color: shade($accent-color, 10%);
				}
			}
		}
	}

	blockquote {
		line-height: 1.2;
		border-left-color: $accent-color;
		margin-left: 0;
		font-family: $body-family;
		font-weight: 400;
		font-style: normal;

		font-size: rem(24);
		border-left-width: rem(5);
		@include media($lap-min-width){
			font-size: rem(36);
			border-left-width: rem(10);
			line-height: 1.15;
		}
	}

}
