@import 'bourbon/bourbon';
@import 'neat/neat';

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import 'core/variables';
@import 'core/mixins';
@import 'core/global';
@import 'core/utility-class';
@import 'core/layout';
@import 'core/nav';

@import 'sect/hero';
@import 'sect/about';
@import 'sect/portfolio';
@import 'sect/resume';
@import 'sect/contact';
@import 'sect/post';

// Temporary: Remove once done
header.notice {
	display: block;
	position: relative;
	margin: 0;
	border: 0;
	background-color: $accent-color;
	padding: rem(15);

	span {
		letter-spacing: 0.2px;
		color: $white100;
		font-size: rem(16);
		font-weight: 400;

		a {
			text-decoration: underline;
			color: $white300;
		}
	}
}

// init wow
.wow {
	visibility: hidden;
}

// noscript stuff
.no-js {
	body {
		opacity: 1;
	}

	.wow {
		visibility: visible;
	}
}
