.site-nav {
	$nav-background: $white200;
	$nav-font-family: $suppl-family;
	$nav-font-color: $white200;
	$nav-font-color-hov: $white300;

	border-radius: 0;
	
	&:not(:hover):not(:active):not(:focus) {
		background: transparent;
	}

	.menu-icon {
		height: 36px;

		> svg path {
			fill: $nav-font-color;
		}
	}

	&:hover,
	&:active {
		.menu-icon > svg path {
			fill: $base-color;
		}
	}

	.page-link {
		font-family: $nav-font-family;
		font-weight: 400;

		@include media($mob-min-width){
			font-size: rem(15);
		}

		
		@include media(max-width 600px) {
			color: $base-color;
			padding: rem(10) rem(15);
		}

		@include media(601px) {
			color: $nav-font-color;

			&:hover,
			&:active {
				cursor: pointer;
				color: $nav-font-color-hov;

				@include media($mob-min-width){
					color: $nav-font-color-hov;
				}
			}
		}
	}

}

.site-header {
	@include sect-side-padding;
	background: transparent;
	border-bottom-width: 0;
	margin-bottom: rem(-56);
	z-index: 10;
	// border-top-color: shade($accent-color, 30%);
	border-top-width: 0;
}

.site-footer {
	// $footer-background: $white200;
	$footer-background:	$background-color300;
	$footer-background: linear-gradient(0deg, rgba($base-color200, 0.25) 0%, rgba($base-color200, 0.28) 100%), url("../images/ptrn_ignasi.png");
	$footer-font-family: $suppl-family;
	$footer-color: $base-color200;

	@include clip-path-up;
	position: relative;

	background: $footer-background;

	* {
		font-family: $footer-font-family;
		letter-spacing: 0.3px;
		color: $footer-color;
	}

	a, 
	a * {
		color: shade($footer-color, 20%);
	}

	.icon > svg path {
		fill: shade($footer-color, 20%);
	}

	.wrapper .row {
		@include sect-side-padding;
		padding-top: rem(80); // clip-path version

		@include media($mob-min-width){
			padding-top: rem(100); // clip-path version
		}

		@include media($desk-min-width){
			padding: 0;
			padding-top: rem(100); // clip-path version
		}

		.footer-col {
			padding-left: 0;
			padding-right: 15px;
		}
	}

	.footer-col-wrapper {
		margin-left: 0;

		@include media($desk-min-width){
			padding-left: rem(30);
		}
		@include media($hires-min-width){
			padding-left: 0;
		}
	}
}