@mixin hero-colors {
	$hero-grad-opacity: 0.88;
	$hero-color-1: rgba(shade($base-color300, 40%), $hero-grad-opacity);
	$hero-color-2: rgba(shade($base-color300, 25%), $hero-grad-opacity);
	$hero-img-src: "/images/hero-bg-3.jpg";
	$hero-angle: 0deg;

	background: $background-color300; // fallback for image
	@include background-image(linear-gradient($hero-angle, $hero-color-1 0%, $hero-color-2 100%), url(#{$hero-img-src}));
	background-size: cover;
	background-position: center top;

	* {color: $white100};
}

@mixin sect-padding {
	padding: rem(70) rem(15);
	padding: rem(120) rem(15); // clip-path version

	@include media($mob-min-width){
		padding: rem(100) rem(30);
		padding: rem(150) rem(30); // clip-path version
	}
}

@mixin sect-side-padding {
	padding: 0 rem(15);

	@include media($mob-min-width){
		padding: 0 rem(30);
	}
}

@mixin content-bg {
	padding: rem(10) rem(15);
	padding-left: 0;
}

@mixin title-styling {
	font-family: $header-family;
	line-height: 1;
	font-weight: 600;
	font-style: italic;
	text-transform: uppercase;
	color: $base-color200;
	margin: 0;
}

@mixin subtitle-styling {
	font-size: rem(19);
	font-family: $header-family;
	font-weight: 300;
	color: $base-color200;
	letter-spacing: 0.3px;

	@include media($mob-min-width){
		font-size: rem(20);
	}

	@include media($tab-min-width){
		font-size: rem(22);
	}
}

@mixin inline-block-container {
	display: inline-block;
	position: relative;
	width: 100%;
}

@mixin block-tags($color: $base-color) {
	margin: rem(3) rem(6) rem(3) 0;
	padding: rem(8) rem(12);
	border: solid 1px $color;
	color: $color;
	display: block;
}

@mixin inline-lists {
	list-style: none;
	margin: 0;
	float: left;

	li {
		display: inline-block;
		float: left;
	}
}

@mixin clip-path-both {
	margin-top: -20px;
	margin-bottom: -20px;
	-webkit-clip-path: polygon(0 20px, 100% 0, 100% calc(100% - 20px), 0% 100%);
	clip-path: polygon(0 20px, 100% 0, 100% calc(100% - 20px), 0% 100%);

	@include media($tab-min-width){
		margin-top: -30px;
		margin-bottom: -30px;
		-webkit-clip-path: polygon(0 30px, 100% 0, 100% calc(100% - 30px), 0% 100%);
		clip-path: polygon(0 30px, 100% 0, 100% calc(100% - 30px), 0% 100%);
	}

	@include media($desk-min-width){
		margin-top: -40px;
		margin-bottom: -40px;
		-webkit-clip-path: polygon(0 40px, 100% 0, 100% calc(100% - 40px), 0% 100%);
		clip-path: polygon(0 40px, 100% 0, 100% calc(100% - 40px), 0% 100%);
	}

	@include media($hires-min-width){
		margin-top: -60px;
		margin-bottom: -60px;
		-webkit-clip-path: polygon(0 60px, 100% 0, 100% calc(100% - 60px), 0% 100%);
		clip-path: polygon(0 60px, 100% 0, 100% calc(100% - 60px), 0% 100%);
	}
}

@mixin clip-path-up {
	-webkit-clip-path: polygon(0 20px, 100% 0, 100% 100%, 0% 100%);
	clip-path: polygon(0 20px, 100% 0, 100% 100%, 0% 100%);
	margin-top: -20px;

	@include media($tab-min-width){
		-webkit-clip-path: polygon(0 30px, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 30px, 100% 0, 100% 100%, 0% 100%);
		margin-top: -30px;
	}

	@include media($desk-min-width){
		-webkit-clip-path: polygon(0 40px, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 40px, 100% 0, 100% 100%, 0% 100%);
	}

	@include media($hires-min-width){
		-webkit-clip-path: polygon(0 60px, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 60px, 100% 0, 100% 100%, 0% 100%);
	}
}

@mixin clip-path-down {
	-webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 0% 100%);
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 0% 100%);
	margin-bottom: -20px;

	@include media($tab-min-width){
		-webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), 0% 100%);
		margin-bottom: -30px;
	}

	@include media($desk-min-width){
		-webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), 0% 100%);
		margin-bottom: -40px;
	}

	@include media($hires-min-width){
		-webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 60px), 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 60px), 0% 100%);
		margin-bottom: -60px;
	}
}
