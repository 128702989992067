.hero {
	@include hero-colors;
	padding-top: 0;
	padding-bottom: 0;

	background-position: center 22%;

	@include media($tab-min-width) {
		background-position: center 20%;

	}

	@include media($desk-min-width) {
		background-position: center 19%;
	}

	.hero-wrapper {
		display: block;
		position: relative;
		max-width: $content-width;
		margin: 0 auto;
	}

	.row {
		@include display(flex);
		@include align-items(flex-end);
		@include justify-content(flex-start);
		height: rem(320);

		@include media($mob-min-width){
			height: rem(330);
		}
		@include media($tab-min-width){
			height: rem(360);
		}
		@include media($lap-min-width){
			height: rem(390);
		}
		@include media($hires-min-width){
			// height: rem(500);
			height: rem(450);
		}
	}

	.content {
		width: 100%;
		margin: rem(50) 0;
	}

	.title {
		@include title-styling;
		color: $white300;
		font-size: rem(42);

		@include media($mob-min-width){
			font-size: rem(48);
		}
		@include media($tab-min-width){
			font-size: rem(55);
		}
	}

	.subtitle {
		@include subtitle-styling;
		color: $white300;

		a {
			// color: $white100;
			// color: $black300;
			color: $white100;
			font-weight: 400;
		}
	}

	.title-logo {
		max-width: rem(720);
		width: 100%;
	}
}


#hero-name {
	// $hero-stroke-color: $base-color300;
	$hero-stroke-color: $white100;

	margin-bottom: rem(10);

	path {
		// @include animation(name-dash 1.5s ease-in-out forwards);
		fill: transparent;
		stroke: $hero-stroke-color;
		stroke-width: 1px;
		// stroke-dasharray: 900;
		// stroke-dashoffset: 1900;
	}
}

// @include keyframes(name-dash) {
// 	to {
// 		stroke-dashoffset: 0;
// 	}
// }
