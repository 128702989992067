.page-content {
	position: relative;
	padding: 0;
	z-index: 9;
}

.wrapper {
	max-width: 100%;
	padding: 0;

	> section {
		&:not(.hero){
			@include sect-padding;
			@include clip-path-both;
		}

		&.hero {
			@include sect-side-padding;
			@include clip-path-down;
			padding-bottom: rem(30); // clip-path version

			@include media($mob-min-width){
				padding-bottom: rem(50); // clip-path version
			}
		}
	}
}


.page-content-header {
	$hero-grad-opacity: 0.88;
	$hero-color-1: rgba(shade($base-color300, 40%), $hero-grad-opacity);
	$hero-color-2: rgba(shade($base-color300, 25%), $hero-grad-opacity);
	$hero-img-src: "/images/hero-bg-3.jpg";
	$hero-angle: 0deg;
	
	@include hero-colors;
	@include background-image(linear-gradient($hero-angle, $hero-color-1 0%, $hero-color-2 100%), url(#{$hero-img-src}));
	@include sect-side-padding;
	@include clip-path-down;
	background-position: center 40%;
	padding-bottom: rem(30);

	@include media($mob-min-width){
		padding-bottom: rem(50); // clip-path version
	}

	.layout_post & {
		$hero-img-src: "/images/hero-bg-4.jpg";

		@include background-image(linear-gradient($hero-angle, $hero-color-1 0%, $hero-color-2 100%), url(#{$hero-img-src}));
		background-position: center 60%;
	}

	.header-wrapper {
		@include display(flex);
		@include align-items(center);
		@include justify-content(center);

		height: rem(200);
		width: 100%;
		margin: 0 auto;

		@include media($mob-min-width) {
			height: rem(120);
		}
	}

	.header-content {
		max-width: $content-width;
		width: 100%;
	}

	.title {
		@include title-styling;
		color: $white300;
		font-size: rem(40);

		@include media($tab-min-width){
			font-size: rem(44);
		}
	}

	.subtitle {
		@include subtitle-styling;
		color: $white300;
	}
}

.page-heading {
	$page-heading-font-family: $header-family;
	$page-heading-color: $base-color300;

	font-family: $page-heading-font-family;
	font-size: rem(26);
	font-style: italic;
	text-transform: uppercase;
	font-weight: 600;
	color: $page-heading-color;

	@include media($mob-min-width){
		font-size: rem(28);
	}

	@include media($tab-min-width){
		font-size: rem(30);
	}
}