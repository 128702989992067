.recent-work {
	background: $white100;
}

.portfolio {
	background-color: $white150;
}

.recent-work,
.portfolio .list {

	ul {
		@include outer-container(100%);
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	li:not([class$="desandro"]) {
	// li {
		@include span-columns(12);
		float: initial;
		margin-right: 0;
		// box-shadow: $shadow1;

		@include media(min-width $mob-min-width max-width $tab-min-width) {
			@include span-columns(6);
			float: initial;
			// margin-right: 0;

			&:nth-child(2n+2){
				@include omega();
			}
		}
		@include media(min-width $tab-min-width max-width $desk-min-width) {
			@include span-columns(6);
			float: initial;
			// margin-right: 0;

			&:nth-child(2n+2){
				@include omega();
			}
		}
		@include media($desk-min-width){
			@include span-columns(4);
			float: initial;

			&:nth-child(3n+3){
				@include omega();
			}
		}
	}

	.post-img {
		display: block;
	}

	.post-desc {
		// $post-link-color: $black300;
		$post-link-color: $base-color200;

		@include content-bg;

		.post-link {
			@include transform(scaleY(1.1));
			// @include transform(translateY(-60px));
			// background: rgba($white100, 0.7);
			position: relative;
			font-family: $header-family;
			font-weight: 400;
			font-style: italic;
			text-transform: uppercase;
			line-height: 1;
			margin: 0;

			// padding: rem(5) rem(10);
			// border-left: solid rem(3) $accent-color;
			padding: rem(5) rem(10);
			padding-left: 0;

			a {
				font-size: rem(18);
				color: $post-link-color;
			}
		}

		.post-meta {
			font-family: $suppl-family;
			text-transform: uppercase;
		}

		.post-categories {
			display: block;
			font-size: rem(14);
			color: $black500;
			margin-top: rem(5);

			li {
				position: relative;
				display: inline-block;
				padding: rem(3) rem(6);
				margin: 0;
				margin-right: rem(4);
				margin-bottom: rem(4);
				width: auto;
				border: solid 1px tint($black500, 20%);
			}
		}
	}
}

.recent-work {
	li:not([class$="desandro"]) {
		@include media($desk-min-width) {
			@include span-columns(3);
			float: initial;

			&:nth-child(3n+3){
				margin-right: 2.35765%;
			}

			// &:nth-child(4n+4) {
			// 	@include omega();
			// }
		}
	}
}

.portfolio .navigation {
	// $pf-link-color: shade($accent-color, 10%);
	$pf-link-color: $base-color200;

	.portfolio-filter,
	.portfolio-sort {
		@include span-columns(12);
		margin-bottom: rem(15);

		@include media($lap-min-width){
			margin-bottom: rem(30);
			&.portfolio-filter {@include span-columns(9);}
			&.portfolio-sort {
				@include span-columns(3);
				@include omega();
			}
		}
	}

	.filter-header {
		margin: 0;
		margin-bottom: rem(5);
		font-family: $suppl-family;
		font-weight: 400;
		color: $base-color200;
	}

	.portfolio-filter-list {
		@extend .inline-lists;

		li button {
			@include block-tags($pf-link-color);
			background-color: transparent;
			font-family: $suppl-family;
			font-size: rem(14);
			@include transition(all 0.25s ease-in-out);

			&.checked,
			&:hover,
			&:active {
				cursor: pointer;
				border-color: tint($pf-link-color, 30%);
				color: tint($pf-link-color, 30%);
			}

			&:focus {
				outline: none;
			}
		}
	}
}

.portfolio .post-list.desandroed  {
	display: inline-block;
	width: 100%;
	justify-content: initial;
	align-items: initial;
	flex-wrap: initial;
	// position: relative;

	li {
		@include media($mob-min-width) {
			margin-bottom: rem(20);
			// margin-right: 2.55%;
		}

		// @include media($tab-min-width) {
		// 	margin-right: 1.72%;
		// }
	}
}

.post-sizer-desandro,
.portfolio .post-item-desandro {
	float: left;
	display: inline-block;
	position: relative;
	width: percentage(1);
	overflow: hidden;

	@include media($mob-min-width) { width: percentage(0.4745);}
	@include media($tab-min-width) { width: percentage(0.3128);}
	// @include media($hires-min-width){ width: percentage(0.230);}
	// @include media($desk-min-width){ width: percentage(0.185);}

	&.featured {
		@include media($lap-min-width) { width: percentage(0.230 * 2 + 0.019);}
	}

	* {
		@include transition(0.4s all ease-in-out);
	}


	&:hover,
	&.no-mouse {
		img {
			@include filter(grayscale(10%) brightness(90%));
		}

		.post-desc {
			@include transform(translateY(1px));
		}
	}

	.post-desc {
		background-color: rgba($white200, 0.9);
		// @include background-image(linear-gradient(0deg, rgba($white200, 0.8) 10%, rgba($white200, 0.8) 60%));
		@include transform(translateY(200px));
		position: absolute;
		bottom: 0;
		width: 100%;
		padding-left: rem(10);
	// display: none;
	// min-height: rem(140);
	// @include media($mob-min-width){ min-height: rem(140);}
	// @include media($tab-min-width){ min-height: rem(170);}
	// @include media($lap-min-width){ min-height: rem(166);}
	// @include media($desk-min-width){ min-height: rem(170);}
	// @include media($hires-min-width){ min-height: rem(160);}
}
}

.post-gutter-desandro {
	width: percentage(0);

	@include media($mob-min-width){ width: percentage(0.029);}
	@include media($tab-min-width){ width: percentage(0.024);}
	// @include media($hires-min-width){ width: percentage(0.019);}
	// @include media($desk-min-width){ width: percentage(0.014);}
}
