.about {
	background: $background-color200;

	.content {
		@include span-columns(12);
		margin-top: rem(15);

		@include media($tab-min-width){
			@include span-columns(8);
			margin-top: rem(0);
		}

	.description { max-width: rem(700);}

	}

	.image-wrapper {
		@include media($tab-min-width){
			@include span-columns(4);
			@include omega();
			float: right;
		}

		.avater {
			display: block;
		}
	}
	
	.about-cta {
		margin-top: rem(30);
	}

	.skills {
		@extend .inline-lists;

		li span,
		li a {
			@include block-tags;
		}
	}
}

.About {
	background-color: $white150;
}
